export default {
  formId: 'checkoutForm',
  formField: {
    firstName: {
      name: 'firstName',
      label: 'Nombre completo*',
      requiredErrorMsg: 'El nombre completo es obligatorio'
    },
    lastName: {
      name: 'lastName',
      label: 'Apellido*',
      requiredErrorMsg: 'Apellido requerido'
    },
    address1: {
      name: 'address2',
      label: 'dirección*',
      requiredErrorMsg: 'La dirección es obligatoria'
    },

    city: {
      name: 'city',
      label: 'Ciudad*',
      requiredErrorMsg: 'la ciudad es obligatoria'
    },
    state: {
      name: 'state',
      label: 'Estado*',
      requiredErrorMsg: 'El estado es obligatorio'
    },
    zipcode: {
      name: 'zipcode',
      label: 'Codigo ZIP*',
      requiredErrorMsg: 'Codigo ZIP es obligatorio',
      invalidErrorMsg: 'Formato de ZIP inválido'
    },
    country: {
      name: 'country',
      label: 'País*',
      requiredErrorMsg: 'País es obligatorio'
    },
    useAddressForPaymentDetails: {
      name: 'useAddressForPaymentDetails',
      label: 'Dirección para pagos'
    },
    invoiceId: {
      name: 'invoiceId',
      label: 'ID de factura'
    },
    nameOnCard: {
      name: 'nameOnCard',
      label: 'Nombre en tarjeta*',
      requiredErrorMsg: 'Nombre en tarjeta es requerido'
    },
    cardNumber: {
      name: 'cardNumber',
      label: 'Numero de Tarjeta*',
      requiredErrorMsg: 'Numero de tarjeta requerida',
      invalidErrorMsg: 'Numero de tarjeta no es valido (e.g. 4111111111111)'
    },
    expiryDate: {
      name: 'expiryDate',
      label: 'Fecha de expiracion*',
      requiredErrorMsg: 'Fecha de expiracion es requerido',
      invalidErrorMsg: 'Fecha de expiracion no es valido'
    },
    cvv: {
      name: 'cvv',
      label: 'CVV*',
      requiredErrorMsg: 'CVV es requerido',
      invalidErrorMsg: 'CVV es invalido (e.g. 357)'
    }
  }
};
